.new-img-gallery {
  width: 100%;
  box-sizing: border-box;
  display: block;

  .image-gallery-image {
    width: 100% !important;
    // height: 300px !important;
    max-height: 300px !important;
    background-color: #202020;
  }

}

.image-gallery-bullets-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -0.5px !important;
}

.image-gallery-bullets {
  background: rgba(0, 0, 0, 0.7); /* Dark background */
  border-radius: 30px;
  padding: 5px 6px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  width: fit-content;
}

/* Style the bullets */
.image-gallery-bullet {
  width: 7px !important;
  height: 7px !important;
  border:1px solid #faa61a !important;
  border-radius: 50%;
  background: white !important;
  opacity: 1 !important;
  transition: all 0.3s ease-in-out;
  padding: 2px !important;
  margin: 0 2px !important;
}

/* Active bullet */
.image-gallery-bullet.active {
  background: #faa61a !important; 
  border:1px solid #faa61a !important;
  opacity: 1;
  transform: scale(1) !important;
}