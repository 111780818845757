.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.h-full {
  height: 100vh;
}

.d-flex {
  display: flex;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "GothamBold" !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Globally style the Checkbox */
.MuiCheckbox-root {
  color: #fff !important;
}

.MuiCheckbox-root.Mui-checked {
  color: #fff !important;
}

/* Globally style the Radio Button */
// .MuiRadio-root {
//   color: #fff !important;
// }

// .MuiRadio-root.Mui-checked {
//   color: #faa61a !important;
// }

.map-popover .slick-prev:before,
.map-popover .slick-next:before {
  font-family: "slick";
  font-size: 30px !important;
  line-height: 1;
  opacity: 0.75;
  color: #faa61a !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.map-popover .slick-prev,
.map-popover .slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 30px !important;
  height: 30px !important;
  padding: 0;
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  // background: transparent;
  background: transparent !important;
  border-radius: 50% !important;
  padding: 2px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.map-popover .slick-next {
  right: -16px !important;
  z-index: 100;
}

.map-popover .slick-prev {
  left: -16px !important;
  z-index: 100;
}

.custom-arrow {
  background-color: #000000ed;
  border-radius: 3px;
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
  position: absolute;
  top: 57%;
  transform: translateY(-50%);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1px;
  height: 10px;
  color: #faa61a;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.prev-arrow {
  left: -10px;
}
.prev-arrow.disabled {
  // display: none !important;
  // visibility: hidden !important;
  background-color: transparent;
  color: transparent;
  box-shadow: none;
}

.next-arrow {
  right: -10px;
}

.next-arrow.disabled {
  // display: none !important;
  // visibility: hidden !important;
  background-color: transparent;
  color: transparent;
  box-shadow: none;
}

.prev-arrow.visibility-hidden {
  display: none !important;
  visibility: hidden !important;
}
.next-arrow.visibility-hidden {
  display: none !important;
  visibility: hidden !important;
}
