.gps-home {
    width: 100%;
    box-sizing: border-box;

    .gps-home-grid-right-form {
        display: flex;
        flex-direction: column;
        gap: 13px;
        width: 100%;
        box-sizing: border-box;
    }

    .gps-home-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 5rem;
        align-items: flex-start;
        padding: 1rem;
        box-sizing: border-box;

        @media (max-width: 992px) {
            gap: 2rem !important;
        }

        @media (max-width: 768px) {
            gap: 1rem !important;
        }
    }

    .gps-home-main {
        padding: 1.5rem 0;
    }

    .gps-home-grid-right {
        position: -webkit-sticky;
        position: sticky;
        top: 8rem;
        background-color: #fff;
        border-radius: 10px;
        border: 5px solid #faa61a;
        padding: 1rem 1rem;

        @media (max-width: 992px) {
            position: relative !important;
            top: 0 !important;
            order: 1 !important;

            .gps-home-grid-left {
                order: 2 !important;
            }
        }

        .gps-home-input-field {
            border: 1px solid gray;
            color: #2d2d2d;
            border-radius: 10px;
            outline: none;
            box-shadow: unset;
            width: auto;
        }

        .gps-home-input {
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            color: #2d2d2d;
            font-size: 17px;
            // margin: 0.4rem 0;
            font-family: 'Gotham' !important;
        }

        .gps-home-input-2 {
            display: grid;
            grid-template-columns: 1fr 1fr;
            align-items: center;
            color: #2d2d2d;
            font-size: 17px;
            font-family: 'Gotham' !important;
            position: relative;

            input[type=number]::-webkit-inner-spin-button,
            input[type=number]::-webkit-outer-spin-button {

                opacity: 1;
                
            }
        }

        .gps-home-input-3 {
            display: flex;
            align-items: center;
            gap: 0.5rem;
            font-size: 17px;
            color: #2d2d2d;
            // margin: 0.4rem 0;
            font-family: 'Gotham' !important;
        }

        .gps-home-input-3-radio {
            display: flex;
            align-items: center;
            gap: 0.4rem;
            font-size: 17px;
            font-family: 'Gotham' !important;

            input[type=radio] {
                border: 2px solid #2d2d2d !important;
                width: 20px;
                height: 20px;
            }
        }

        .gps-home-grid-right-heading {
            font-size: 40px !important;
            font-weight: bold;
            color: #faa61a;
            font-family: 'Montserrat' !important;
            font-weight: 800;

            @media (max-width: 576px) {
                font-size: 33px !important;
            }

            @media (max-width: 400px) {
                font-size: 30px !important;
            }

            @media (max-width: 320px) {
                font-size: 26px !important;
            }
        }

        .gps-home-input-field {
            border: 2px solid gray;
            color: #2d2d2d;
            border-radius: 6px;
            outline: none;
            box-shadow: unset;
            width: auto;
            background-color: #e3e3e3;
        }

        .get-gps-btn-div {
            // margin-top: 1rem;
            margin-top: 0;
            display: flex;
            justify-content: center;
            align-items: center;

            .get-gps-btn {
                font-family: 'GothamBold' !important;
                color: #fff;
                background-color: #149247;
                border-radius: 15px;
                width: 95%;
                font-size: 22px;
                line-height: 24px;
                padding: 10px 0 !important;

                @media (max-width: 576px) {
                    font-size: 20px !important;
                    width: 85%;
                }

                &:hover {
                    background-color: #085828;
                }
            }
        }

        .gps-home-grid-right-btm-p {
            text-align: center;
            // margin-top: 1rem !important;
            margin-top: 0;
            font-family: 'Gotham' !important;
            font-weight: 300 !important;
            color: #000 !important;
        }
    }



    .gps-home-grid-left-head {
        display: flex;
        align-items: center;
        gap: 0.2rem;
        width: 100%;
    }

    .gps-home-grid-2-left {
        // width: 30%;
        // padding-top: 1rem;
    }

    .gps-home-grid-3-left {
        width: 30%;
    }

    .gps-home-grid-left-head-img {
        width: 300px;
        height: 250px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: -1rem;

        @media (max-width: 1500px) {
            width: 280px !important;
            height: 200px !important;
        }

        @media (max-width: 1400px) {
            width: 240px !important;
            height: 185px !important;
        }

        @media (max-width: 1300px) {
            width: 210px !important;
            height: 165px !important;
        }

        @media (max-width: 1200px) {
            width: 180px !important;
            height: 145px !important;

        }

        @media (max-width: 992px) {
            width: 140px !important;
            height: 95px !important;
        }

        @media (max-width: 576px) {
            width: 140px !important;
            height: 95px !important;
        }

        @media (max-width: 400px) {
            width: 150px !important;
            height: 100px !important;
        }

        @media (max-width: 320px) {
            width: 100px !important;
            height: 80px !important;
        }

        img {
            width: 100%;
            height: 100%;
            display: block;
            object-fit: contain;
        }
    }

    .gps-home-grid-left-heading {
        // font-size: clamp(25px, 3vw, 40px) !important;
        // line-height: clamp(30px, 3vw, 45px) !important;
        // font-size: 24px !important; // New changes

        font-size: 45px;
        line-height: 50px;

        color: #faa61a;
        font-family: 'Montserrat' !important;
        text-align: center;
        font-weight: 900 !important;

        @media (max-width: 1500px) {
            font-size: 37px !important;
            line-height: 42px !important;
        }

        @media (max-width: 1400px) {
            font-size: 37px !important;
            line-height: 42px !important;
        }

        @media (max-width: 1300px) {
            font-size: 35px !important;
            line-height: 40px !important;
        }

        @media (max-width: 1200px) {
            font-size: 31px !important;
            line-height: 36px !important;

        }

        @media (max-width: 992px) {
            font-size: 35px !important;
            line-height: 40px !important;
        }

        @media (max-width: 576px) {
            font-size: 30px !important;
            line-height: 35px !important;
        }

        @media (max-width: 400px) {
            font-size: 26px !important;
            line-height: 32px !important;
        }

        @media (max-width: 320px) {
            font-size: 21px !important;
            line-height: 27px !important;
        }
    }

    .gps-home-cmmn-heading {
        font-size: 30px !important;
        color: #faa61a;
        font-family: 'Montserrat' !important;
        text-align: center;
        font-weight: 800 !important;

        @media (max-width: 576px) {
            font-size: 28px !important;
        }

        @media (max-width: 320px) {
            font-size: 22px !important;
        }
    }

    .gps-home-cmmn-p {
        color: #fff !important;
        text-align: center;
    }

    .gps-home-gen-spec {
        margin: 2rem 0;

        @media (max-width: 576px) {
            margin: 2rem 0 0 0 !important;
        }

    }

    .gps-home-gen-spec-item {
        display: flex;
        flex-direction: column;
        margin: 0.5rem 0;

        .gps-home-gen-spec-item-h {
            color: #faa61a !important;
            font-family: "GothamBold" !important;
            font-size: 18px !important;
            margin-bottom: 0.2rem !important;
        }

        .gps-home-gen-spec-item-p {
            color: #fff !important;
            font-family: 'Montserrat' !important;
            font-weight: 400;

        }

        .gps-home-spec-item-ps {
            font-size: 12px !important;
            color: #fff;
            font-family: 'Montserrat' !important;
            font-weight: 300;
        }
    }

    .gps-home-quote {
        border-top: 2px solid gray;
        border-bottom: 2px solid gray;
        padding: 2rem 0;
    }

    .gps-home-quote-p {
        // color: #fff;
        color: #ffffff;
        text-align: center;
        font-style: italic;
        font-size: 16px !important;
        margin-bottom: 0.5rem !important;
        width: 80%;
        margin-left: auto !important;
        margin-right: auto !important;
        position: relative;
        font-family: 'Montserrat' !important;
        font-weight: 400;

        @media (max-width: 320px) {
                font-size: 14px !important;
            }

    }

    .gps-home-quote-inner {
        position: relative;

        .gps-quote-icon-1 {
            position: absolute;
            left: 0px;
            width: 40px;
            height: 40px;
            display: block;
            top: -20px;
            object-fit: contain;

            @media (max-width: 320px) {
                width: 25px !important;
                height: 25px !important;
            }
        }


        .gps-quote-icon-2 {
            position: absolute;
            right: 0px;
            width: 40px;
            height: 40px;
            display: block;
            bottom: 5px;
            object-fit: contain;

            @media (max-width: 320px) {
                width: 25px !important;
                height: 25px !important;
            }
        }
    }

    .gps-home-quote-ps {
        //color: #fff;
        color: #7f7f7f !important;
        text-align: center;
        font-style: italic;
        font-size: 12px !important;
    }

    .gps-home-cmmn-pb {
        color: #fff !important;
        text-align: center;
        font-size: 21px !important;

        @media (min-width:993px) and (max-width:1200px) {
                font-size: 16px !important;
            }

        @media (max-width: 576px) {
            font-size: 16px !important;
        }
    }

    .gps-home-grt-opt-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        margin-top: 1rem;
    }

    .gps-home-grt-opt-p {
        color: #fff;
        text-align: center;
        font-family: 'GothamBold' !important;
    }

    .gps-home-gray-line {
        height: 2px;
        background-color: gray;
        width: 70%;
        margin: 0.5rem auto;
        border: none;
    }

    .gps-home-grt-opt-ul {
        list-style: square;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        gap: 0.4rem;
        padding-left: unset !important;

        .gps-home-grt-opt-li {
            color: #fff;
            display: flex;
            align-items: center;
            gap: 0.5rem;

            img {
                //width: 15px;
                //height: 15px;
                width: 10px; // New Change
                height: 10px; // New Change
                display: block;
            }
        }

    }

    .gps-home-img-div {
        margin: 2rem 0;
        margin-bottom: 15px; //new change

        img {
            display: block;
            width: 100%;
            height: 100%;
            // max-height: 150px;
            object-fit: contain;
        }
    }

    .grid-home-grid-2-right-ul {
        list-style: none;
        padding-left: unset;
        margin-bottom: 0;
        margin-top: 0;

        li {
            color: #fff;
            display: flex;
            align-items: center;
            gap: 0.8rem;
            margin: 0.4rem 0;
            font-size: 18px;
            font-family: 'Montserrat' !important;
            font-weight: 400;

            @media (min-width:993px) and (max-width:1200px) {
                font-size: 16px !important;
            }

            img {
                //width: 25px;
                //height: 25px;
                width: 16px; // New change
                height: 18px; // New change
                display: block;
            }
        }
    }

    .gps-home-grid-2 {
        // display: flex;
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: flex-start;
        gap: 1rem;
        margin: 1.5rem 0;
        // justify-content: center;
        // justify-content: space-between;

        .gps-home-grid-2-left {
            // width: 150px;
            // height: 150px;

            img {
                width: 160px;
                height: 130px;
                display: block;
                object-fit: contain;
            }
        }
    }

    .gps-home-grid-3 {
        display: flex;
        gap: 1rem;
        // justify-content: center;
        // justify-content: space-between;
        border-top: 2px solid gray;
        border-bottom: 2px solid gray;
        padding: 1rem 0;
        margin: 1rem 0;



        .gps-home-grid-3-left {

            // width: 150px;
            // height: 150px;
            // width:30%; //New added
            img {
                width: 120px;
                height: 120px;
                display: block;
                object-fit: contain;
            }
        }
    }

    .grid-home-grid-3-right-ul {
        list-style: none;
        padding-left: unset;

        li {
            color: #fff;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            margin: 0.4rem 0;
            font-size: 16px;
            

            img {
                //width: 20px;
                //height: 20px;
                width: 10px; // New change
                height: 10px; // New Change
                display: block;
            }
        }
    }

    .gps-home-input-2.devices-input-grp {
        display: flex;

        label {
            width: 80%;
        }

        input {
            width: 20%;
        }
    }






    .tab-button-1.active {
        font-family: "GothamBold" !important;
        color: #ffffff !important;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: clamp(20px, 3vw, 34px) !important;
        background-color: #47463E;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.7);
        padding-bottom: 6.5rem !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        border-radius: 10px !important;
        width: max-content;

        @media (max-width: 320px) {
            font-size: 18px !important;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }
    }

    .tab-button-1 {
        font-family: "GothamBold" !important;
        color: #707070 !important;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: clamp(20px, 3vw, 34px) !important;
        padding-bottom: 5rem !important;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        border-radius: 10px !important;
        width: max-content;

        @media (max-width: 320px) {
            font-size: 18px !important;
            padding-left: 1rem !important;
            padding-right: 1rem !important;
        }

        &:hover {
            background-color: #47463E !important;
        }
    }

    .tab-grid-1 {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 1rem 1rem 1rem;
        background: #2D2D2D;
        border: 3px solid #faa61a;
        border-radius: 17px;
        margin-top: -8rem;
        z-index: 100;
        position: relative;
        box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.5);

    }

    .tab-grid-1-in {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 0.5rem;
        width: 100%;
        box-sizing: border-box;

        

        @media (max-width: 576px) {
            flex-direction: column !important;
            align-items: center !important;
        }

        h3 {

            @media (min-width: 993px) and (max-width: 1200px) {
                    font-size: 2rem !important;
                }

            @media (max-width: 576px) {
                font-size: 2.3rem !important;
            }

            @media (max-width: 400px) {
                font-size: 1.8rem !important;
            }
        }
    }

    .yellow-sqr {
        width: 10px;
        height: 10px;
        background: #faa61a;
    }

    .tab-grid-1-img img {
        width: 160px;
        height: auto;
        display: block;
        object-fit: contain;

        @media (min-width: 993px) and (max-width: 1200px) {
                width: 120px !important;
            }

        @media (max-width: 576px) {
            width: 120px !important;
        }
    }

}


@media (max-width:992px) {
    .gps-home-grid {
        grid-template-columns: 1fr !important;
    }

        .gps-home-grid-left {
            order: 2 !important;
        }
}

// @media (max-width:850px) {
//     .gps-home-grid {
//         grid-template-columns: 1fr !important;
//     }
// }

@media (max-width:768px) {

    .gps-home-grid-2 .gps-home-grid-2-left img {
        width: 100px !important;
        height: 100px !important;
    }

    .gps-home-grid {
        grid-template-columns: 1fr !important;
    }

    // .gps-home-grid-2 .gps-home-grid-2-left {
    //     width: 100px !important;
    //     height: 100px !important;
    // }

    .grid-home-grid-2-right-ul li {
        font-size: 16px !important;
    }

    // .gps-home-grid-3 .gps-home-grid-3-left {
    //     width: 100px !important;
    //     height: 100px !important;
    // }

    .gps-home-grid-3 .gps-home-grid-3-left img {
        width: 100px !important;
        height: 100% !important;
    }

    .grid-home-grid-3-right-ul li {
        font-size: 16px !important;
    }

    .gps-home-cmmn-heading {
        font-size: 25px !important;
    }

    .gps-home-grid-left-heading {
        font-size: 28px !important;
    }
}



@media (max-width: 576px) {

    .gps-home-cmmn-p {
        font-size: 16px !important;
    }

    .gps-home-cmmn-pb {
        font-size: 14px !important;
    }

    .gps-home-grt-opt-li {
        font-size: 12px !important;
    }

    .gps-home-grid-left-heading {
        font-size: 24px !important;
    }

    // .gps-home-grid-left-head-img {
    //     width: 180px !important;
    //     height: 180px !important;
    // }

    .gps-home-cmmn-heading {
        font-size: 24px !important;
    }
}

@media (max-width:320px) {
    .gps-home-grid-2 .gps-home-grid-2-left img {
        width: 60px !important;
        height: 60px !important;
    }

    .grid-home-grid-2-right-ul li {
        font-size: 14px !important;
    }

    .grid-home-grid-3-right-ul li {
        font-size: 14px !important;
    }

    .gps-home-cmmn-heading {
        font-size: 18px !important;
    }

    .gps-home-cmmn-pb {
        font-size: 14px !important;
    }

    .gps-home-grid-left-heading {
        font-size: 20px !important;
    }

    .gps-home-grid-3 .gps-home-grid-3-left img {
        width: 70px !important;
        height: 100% !important;
    }
}

.tab-btn-grp {
    grid-gap: 5px;
    align-items: flex-start;
    display: grid;
    gap: 5px;
    grid-template-columns: repeat(3,1fr);
    margin: 1rem 0;

    
}

.gps-home .tab-grid-1 {
    background: #2d2d2d;
    border: 3px solid #faa61a;
    border-radius: 17px;
    box-shadow: 0 0 30px 0 rgba(0,0,0,.5);
    flex-direction: column;
    gap: 1rem;
    margin-top: -8rem;
    padding: 1rem;
    position: relative;
    z-index: 100;
}

.mandatory-star {
    color: #faa61a !important;
    font-family: "GothamBold" !important;
    font-size: 1rem;
    display: inline-block;
    top: 5px;
    position: relative;
}
.form-control.contact-inputs {
    background-color: #fff;
    border: 2px solid #faa61a;
    border-radius: 6px;
    color: #2d2d2d!important;
    font-family: 'Gotham' !important;
    font-size: 15px;
    padding: .7rem;
    width: 100%;
}