.signup {
    .signup-inner {
        .signup-heading {
            margin: 1rem 0;
            text-align: center;

            p {
                color: #fff;
                font-family: 'GothamBold' !important;
                font-size: clamp(25px, 3.5vw, 80px);
            }
        }

        .signup-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 4rem;
            margin: 0rem 0;
            padding: 1rem 2rem;

            .signup-grid-item {
                padding: 1rem;

                &:first-child {
                    p {


                        &:first-child {
                            color: #faa61a;
                            text-transform: uppercase;
                            font-family: 'GothamBold' !important;
                            font-size: clamp(18px, 3vw, 30px);
                        }

                        &:nth-child(2) {
                            color: #fff;
                            margin: 1rem 0;
                            font-size: clamp(15px, 3vw, 22px);
                        }

                        &:last-child {
                            color: #faa61a;
                            font-family: 'GothamBold' !important;
                            font-size: clamp(18px, 3vw, 30px);
                        }
                    }
                }

                &:last-child {


                    .signup-steps-heading {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        margin-bottom: 1rem;

                        img {
                            display: block;
                            height: 35px;
                            width: 35px;
                            max-height: 35px;
                        }

                        p {
                            color: #fff;
                            font-size: clamp(26px, 3vw, 45px);
                            font-family: 'GothamBold' !important;
                        }
                    }

                    .steps-bar {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-gap: 1rem;
                        margin: 0.5rem 0;
                        width: 40%;

                        .steps-bar {
                            width: 100%;
                            height: 4px;
                            background-color: #faa61a;
                        }

                        .white-bar {
                            background-color: #fff;
                        }
                    }

                    .tellus-text {
                        margin: 1rem 0;

                        p {
                            color: #faa61a;
                            font-size: clamp(14px, 3vw, 26px);
                            font-family: 'GothamBold' !important;
                        }
                    }

                    .signup-fields {
                        .input-div {
                            margin: 1.2rem 0;
                        }

                        .agree-checkbox {
                            color: #fff;
                            font-size: clamp(14px, 3vw, 26px);
                            font-family: 'GothamBold' !important;
                        }
                    }

                    .labels {
                        color: #fff;
                        margin-bottom: 0.3rem;
                    }

                    .input-grid {
                        display: flex;
                        gap: 1rem;
                    }

                    .input-loc-icon {
                        background-color: #fff;
                        height: auto;
                        border: 2px solid #faa61a;
                        border-radius: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        img {
                            display: block;
                            width: 25px;
                            height: 27px;
                            object-fit: contain;
                        }
                    }

                    .next-btn-div {
                        margin: 1.2rem 0;

                        .next-btn {
                            background-color: #149248;
                            // font-family: 'GothamBold';
                            font-weight: 700;
                            border-radius: 10px;
                            color: #fff;
                            box-shadow: none;
                            padding: 0.5rem 3rem;
                            font-size: clamp(20px, 3vw, 28px);
                            line-height: initial;
                        }
                    }
                }
            }
        }
    }
}

.agree-label {
    color: #fff;
    font-size: clamp(14px, 3vw, 17px);
    font-family: 'Gotham';
    padding-left: 0.5rem;
}

.login-main-div.location-modal {
    padding: 2rem 2rem 1rem 2rem;

    .next-btn.cancel-btn-2 {
        margin: 1rem 0;
        background: #faa61a;
        color: #2d2d2d;
    }

    .next-btn.ok-btn {
        margin: 1rem 0;

        background: #faa61a;
        color: #2d2d2d;
    }

    .next-btn-div.location-btn-grp {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: flex-end;
    }
}

@media (max-width:1200px) {}

@media (max-width:1024px) {}

@media screen and (max-width: 992px) {
    .signup-grid {
        display: grid;
        grid-template-columns: repeat(1, 1fr) !important;
        grid-gap: 1rem;
        margin: 2rem 0;
        padding: 0 !important;
    }

    .signup .signup-inner .signup-grid .signup-grid-item {
        padding: 0rem !important;
    }

    .signup-heading {

        p {
            font-size: clamp(25px, 3vw, 40px) !important;
        }
    }

}

@media screen and (max-width:768px) {
    .signup .signup-inner .signup-grid {
        grid-gap: 2rem !important;
    }

    .signup-inner-1 p {
        text-align: justify !important;
    }
}

@media screen and (max-width:576px) {
    .signup-heading {

        p {
            font-size: clamp(20px, 3vw, 35px) !important;
        }
    }

    .signup-steps-heading {
        img {
            display: block;
            height: 25px !important;
            width: 25px !important;
            max-height: 25px !important;
        }
    }

    .signup .signup-inner .signup-grid .signup-grid-item:last-child .steps-bar {
        margin: 0rem 0 !important;
    }

    .resend-div {
        padding: 0rem !important;
        width: 100% !important;
    }
}

@media screen and (max-width:320px) {
    .signup-heading {

        p {
            font-size: clamp(17px, 3vw, 22px) !important;
        }
    }
}

.resend-email {
    text-transform: uppercase;
    font-size: clamp(18px, 3vw, 30px) !important;
    color: #fff !important;
    background-color: #017933 !important;
    border-radius: 7px !important;
    width: 100%;
    padding: 1rem 3rem !important;
    font-family: 'GothamBold' !important;
}

.signup-inner-1 {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 2rem;
    flex-direction: column;
    padding: 3rem 2rem;
    border: 4px solid #faa61a;
    border-radius: 15px;
    background: #111111;
    // background-image: url('../../assets/images/banner_img.png');
    width: 80%;
    margin: auto;

    p {
        font-size: clamp(16px, 3vw, 26px) !important;
        font-family: 'Gotham' !important;
    }
}

.resend-div {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
}

.signup-inner-content-1 {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0rem 0rem;
    // background-color: #000;
    border-radius: 12px;
}

.signup-inner-1 p span {
    font-family: 'GothamBold' !important;
}