.search-section-carousel-item {
  height: 400px;
  margin-top: -6px;

  ul {

    // height: 278px;
    li {
      width: auto !important;
      height: 360px !important;

      img.img-slider {
        width: auto !important;
        height: 100% !important;
        object-fit: contain !important;
      }
    }
  }
}

// .react-multi-carousel-track  li {
//   margin: 0px -23px !important;
// }
.react-multi-carousel-track li {
  margin: 0px 3px !important;
}



// .search-section-carousel-item ul li img {
//   width: 100% !important;
//   max-height: 29vh !important;
//   object-position: top !important;
//   object-fit: cover !important;
//   object-position: center !important;
// }

.carousel-container {
  position: relative;
  border-bottom: 10px solid #faa61a;
}

.search-title-bar {
  display: flex;
  justify-content: center;
  background: #faa61a;
  height: 93px;
  align-items: center;
  margin-bottom: 20px;
}

.search-title {
  font-size: 51.32px !important;
  font-weight: bold !important;
  color: #221f1f !important;
  margin-left: 25px !important;
}

.search-form-container {
  display: flex;
  position: absolute;
  top: 50%;
  height: 364px;
  width: 960px;
  left: 40%;
  transform: translate(-50%, -51%);
  justify-content: center;
  z-index: 100 !important;
}

.search-textfields {
  width: 100%;
  margin: 1rem 0;
  height: 40px;
}

.search-textfields-input {
  width: 90%;
  background-color: white;
  border: 2px solid #faa61a;
  border-radius: 6px;
  outline: none;
  height: 40px;
}

.search-textfields-input:focus {
  outline: none;
}

.search-main-container div:nth-child(4) {
  width: 100% !important;
}

.submit-btn-search-parent {
  width: auto !important;
}

.search-main-container {
  // width: 340px;
  width: 370px;
  background: #2d2d2d;
  text-align: center;
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: -1px;
  // padding-top: 17px;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  z-index: 101;

  .yellow-line {
    // height: 5px;
    // background-color: #faa61a;
    // border: none;
    height: 4px;
    background-color: #faa61a;
    border: none;
    margin: 10px 0px 10px 0px;
  }


  .reset-filter {
    cursor: pointer !important;
    color: #faa61a !important;
    text-align: center;
    margin-top: 15px;
    text-decoration-line: underline;
    font-size: 20px;
    margin-left: 15px;
    font-weight: 520;
  }

  .search-main-container-text {
    display: flex;
    flex-direction: column;
    align-items: center;

  }

  .yellow-text {
    color: #faa61a;
  }

  .yellow-white {
    color: #fff;
  }

  .search-main-container-text:first-child {
    font-size: 30px;
    line-height: 30px;
    font-weight: 900;
    font-size: clamp(1.7rem, 3vw, 1.75rem) !important;
  }

  .search-main-container-text:last-child {
    // color: #fff;
    // margin-bottom: 10px;
    color: #fff;
    margin-bottom: 10px;
    font-size: 21px;
    font-weight: 700;
    font-size: clamp(1.25rem, 3vw, 1.29rem) !important;
  }
}

.img-triangle {
  display: block;
  margin: auto;
  width: 30px;
  height: 15px;
  margin-top: 15px;
}

.img-search-form-border-right,
.img-search-form-border-left {
  margin-top: 0px;
  margin-bottom: -1px;
}

img.img-search-form-border-right {
  margin-left: -24px;
}

.pagination-section {
  display: flex;
  align-items: center;
  // justify-content: flex-end;
  justify-content: center;
  margin: 1rem auto 3rem auto;
  // width: 65%;
  // margin-bottom: -35px;
  max-width: 1200px;
}

.pagination-section-select {
  color: white !important;
  border: 1px solid white !important;
}

.pagination-section-select svg {
  color: white !important;
}

.pagination-section-pageno-inner nav ul li button {
  background-color: #fff;
}

#eq_category,
#eq_location {
  margin-top: -5px;
}

@media only screen and (max-width: 768px) {
  .search-title {
    font-size: 30px !important;
    width: 300px;
  }

  .search-section-carousel-item {
    display: none;
  }

  .search-form-container {
    position: relative;
    width: unset;
    transform: unset;
    left: unset;
    margin-bottom: 25px;
    z-index: unset !important;
  }



  .img-search-form-border-left,
  .img-search-form-border-right {
    display: none;
  }

  .search-main-container {
    // left: 50%;
    position: relative;
    //transform: translateX(-50%);
  }

  .pagination-section {
    justify-content: center;
    width: 100%;
  }


}

@media only screen and (max-width: 425px) {
  .search-form-container {
    z-index: unset !important;
  }

  .search-title {
    font-size: 18px !important;
    width: 200px;
  }
}

.orange-line {
  width: 100%;
  height: 8px;
  background-color: #faa61a;
  border: none;
  margin: 0 !important;
}

// .orange-line-2 {
//   width: 100%;
//   height: 8px;
//   background-color: #faa61a;
//   border: none;
//   margin-top: -22px !important;
// }

.MuiPagination-root ul li button {
  //background-color: #fff;
  color: #fff;
}

.MuiPaginationItem-text {
  color: #fff;
}

.MuiPaginationItem-root.Mui-selected {
  //background-color: #faa61a !important;
  background: none !important;
  color: #faa61a !important;
}

.pagination-section-dd {
  color: white !important;
  //border: 1px solid white !important;
  border: none !important;
  width: 75px;
  height: 40px;
}

.pagination-section-dd svg {
  color: white !important;
}

.page-limit-select {
  color: white !important;
  //border: 1px solid white !important;
  //border: unset !important;
  //outline: unset !important;
  border: none !important;
  height: 40px;
  width: 75px;
}

// .pagination-label{
//   color: #fff;
//   margin-right: 10px;
// }

.field-set-center {
  text-align: center !important;
}

.field-set-center::placeholder {
  text-align: center !important;
}

.field-set-center div label {
  margin: auto !important;
  top: 0px !important;
  left: -40px !important;
  right: 0px !important;
  text-align: center !important;
}

.field-set-center-range label {
  margin: auto !important;
  top: 0px !important;
  left: -35px !important;
  right: 0px !important;
  text-align: center !important;
}

.google-auto-complate {
  text-align: center !important;
  color: #221F1F !important;
}

.google-auto-complate::placeholder {
  text-align: center !important;
}

.field-set-center div div input,
.field-set-center-range div input {
  text-align: center !important;
}

.btn-load-more {
  padding: 5px 13px 5px 20px;
  background-color: #2e2e2e00;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 4px solid #faa61a;
  color: #fff !important;
  text-decoration: none !important;
  font-family: 'Gotham' !important;

  .arrow-load-more {
    width: 35px;
    height: auto;
  }
}

@media screen and (max-width: 900px) {
  // .search-form-container {
    //     position: relative;
    //     width: unset;
    //     transform: unset;
    //     left: unset;
    //     margin-bottom: 25px;
    // z-index: 100 !important;
  // }
}


// New Changes 

.main-search-grid {
  display: grid !important;
  grid-template-columns: repeat(2, 1fr) !important;
  gap: 1rem;
}

.filter-btn {
  background: #149247;
  font-family: 'GothamBold' !important;
  color: #fff;
  width: 100%;
  padding: 0.5rem !important;
  border-radius: 8px !important;

  &:hover {
    background: #0b7736 !important;
  }
}

.cancel-btn {
  background: #faa61a;
  font-family: 'GothamBold' !important;
  color: #fff;
  width: 100%;
  padding: 0.5rem !important;
  border-radius: 8px !important;

  &:hover {
    background: #d48e13 !important;
  }
}



// .date-cal {

//   .rmdp-day,
//   .rmdp-week-day {
//     color: #ffffff !important;
//     cursor: pointer;
//     height: 45px !important;
//     position: relative;
//     width: 45px !important;
//   }

//   .rmdp-calendar {
//     width: 100% !important;
//     // padding: 1rem 2rem 2rem 2rem !important;
//     padding: 1rem !important;
//     height: 450px !important;
//     background: #2d2d2d !important;
//   }

//   .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
//     background-color: rgb(0 87 161) !important;
//     color: #fff;
//   }

//   .rmdp-day.rmdp-today.rmdp-range.start {
//     .twin-cal .twin-cal-custom-calendar .rmdp-day.rmdp-today span {
//       background-color: rgba(16, 110, 190, 0) !important;
//       color: rgb(255 255 255) !important;
//     }
//   }

//   .Calendar__day {
//     display: block;
//     text-align: center;
//     font-size: 1.6em;
//     border-radius: 500px;
//     transition: 0.2s;
//     border: 1px solid transparent;
//     margin-bottom: 0.3em;
//     display: flex !important;
//     justify-content: center !important;
//     align-items: center !important;
//     cursor: pointer;
//     color: #ffffff;
//     width: 100%;
//   }

//   .rmdp-day span {
//     border-radius: 2px;
//     bottom: 3px;
//     // font-size: 13px;
//     font-size: clamp(16px, 3vw, 20px) !important;
//     left: 3px;
//     position: absolute;
//     right: 3px;
//     top: 3px;
//     // width: 100% !important;
//     // height: 100% !important;
//   }

//   .rmdp-range {
//     //   background-color: #faa61a !important;
//     box-shadow: none !important;
//     color: #fff;
//     border-radius: 2px !important;
//   }

//   .rmdp-day.rmdp-today span {
//     background-color: rgb(16 110 190 / 0%) !important;
//     color: rgb(0 147 255) !important;
//   }

//   .rmdp-range-hover.start,
//   .rmdp-range.start {
//     border-bottom-left-radius: 50% !important;
//     border-top-left-radius: 50% !important;
//   }

//   .rmdp-range-hover.end,
//   .rmdp-range.end {
//     border-bottom-right-radius: 50% !important;
//     border-top-right-radius: 50% !important;
//   }

//   .rmdp-range-hover.start:hover,
//   .rmdp-range.start:hover {
//     .sd {
//       border-bottom-left-radius: 50% !important;
//       border-top-left-radius: 50% !important;
//     }
//   }

//   .rmdp-range-hover.end:hover,
//   .rmdp-range.end:hover {
//     .sd {
//       border-bottom-right-radius: 50% !important;
//       border-top-right-radius: 50% !important;
//     }

//   }

//   .rmdp-arrow-container {
//     height: 40px !important;
//     justify-content: center;
//     margin: 0 !important;
//     width: 40px !important;
//     // background-color: rgb(16, 110, 190) !important;
//     display: flex !important;
//     justify-content: center !important;
//     align-items: center !important;
//   }

//   .rmdp-arrow-container.disabled .rmdp-arrow,
//   .rmdp-arrow-container.disabled:hover .rmdp-arrow {
//     border: solid black;
//     border-width: 0 2px 2px 0;
//   }

//   .rmdp-arrow {
//     // border: solid #000 !important;
//     border-width: 0 2px 2px 0;
//     display: inline-block;
//     height: 8px !important;
//     margin-top: 0px !important;
//     padding: 0px !important;
//     width: 8px !important;
//     color: #000 !important;
//   }

//   // .rmdp-calendar {
//   //   width: 100% !important;
//   // }

//   .rmdp-arrow {
//     border: solid #0074d9;
//     border-width: 0 2px 2px 0;
//     display: inline-block;
//     height: 3px;
//     margin-top: 5px;
//     padding: 2px;
//     width: 3px;
//   }

//   .rmdp-header {
//     // font-size: 16px !important;
//     font-size: clamp(18px, 3vw, 22px) !important;
//     width: 100% !important;
//     max-width: 100% !important;
//     color: #fff !important;
//   }

//   .rmdp-header-values {
//     color: #fff !important;
//   }

//   .rmdp-day.rmdp-today span {
//     //   background-color: #faa61a !important;
//     color: #fff;
//     box-shadow: none !important;
//   }

//   .rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
//     //   background-color: #faa61a !important;
//     color: #fff;
//     box-shadow: none !important;
//   }

//   .rmdp-day,
//   .rmdp-week-day {
//     color: #fff !important;
//     font-weight: 300 !important;
//   }

//   .rmdp-day.rmdp-disabled {
//     color: rgb(99, 99, 99) !important;
//   }

//   .rmdp-week-day {
//     color: #ffffff !important;
//     font-size: 18x;
//     font-weight: 500;
//   }
// }


.search-select {
  .MuiInputLabel-root {
    color: #2d2d2d !important;
  }
}


span.cross-icon {
  width: 40px;
  font-size: 21px;
  position: absolute;
  color: #323131;
  right: 0;
  top: 10px;
  font-weight: 700;
  cursor: pointer;
}

// span.filter-cross-icon {
//   width: 40px;
//   font-size: 18px;
//   position: absolute;
//   color: #faa61a;
//   right: -15px;
//   top: 25px;
//   font-weight: 700;
//   cursor: pointer;
// }

span.filter-cross-icon {
  width: 40px;
  font-size: 18px;
  position: absolute;
  color: #faa61a;
  right: 0px;
  top: 50%;
  font-weight: 700;
  cursor: pointer;
  transform: translate(0px, -50%);
}

.search-filter-icon {
  width: 80%;
  cursor: pointer;
}

.filter-search {
  // padding: 10px;

  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;

  .filter-search-tags {
    background-color: #d48e13;
    padding: 10px;
    border-radius: 5px;
    color: #fff !important;
    width: fit-content;
    width: -moz-fit-content;
    font-family: 'GothamBold' !important;

    span.tag-cross-icon {
      color: #323131;
      cursor: pointer;
      margin-left: 10px;
      font-family: 'GothamBold' !important;
    }
  }
}


span.keyword-cross-icon {
  width: 40px;
  font-size: 21px;
  position: absolute;
  color: #323131;
  cursor: pointer;
  margin-left: -35px;
  margin-top: 10px;
}


.search-result {
  position: sticky;
  position: -webkit-sticky;
  top: 6.4rem;
  z-index: 900;
  background: #2d2d2d;
  // background: linear-gradient(180deg, #2D2D2D 80%, rgba(45, 45, 45, 0.00) 100.36%);
  padding: 1rem 1rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

// .search-main {}

.search-div {
  width: 100%;

  .search-grid {
    display: flex;
    // grid-template-columns: 1fr auto;
    align-items: center;
    row-gap: 1rem;
    column-gap: 1rem;


    .MuiAutocomplete-endAdornment .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
      color: #faa61a !important;
      font-size: 24px;
      margin-top: -1px;
    }

    .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
      display: flex !important;
    }
  }


  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
    padding-top: 7px;
    padding-bottom: 7px;
  }

  .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
    padding-top: 3px;
    padding-bottom: 3px;
    height: 50px;
  }
}

.filter-tag-grid {
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  padding: 0 1rem;
}

.search-input {
  position: relative;
}

.filter-popup {
  .MuiInputBase-root.MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl.MuiInputBase-adornedEnd.MuiAutocomplete-inputRoot {
    padding: 3px 12px;
  }

  .MuiInputBase-input.MuiFilledInput-input.css-10botns-MuiInputBase-input-MuiFilledInput-input {
    padding: 3px 12px;
    height: 34px;
  }

  .MuiInputBase-root.MuiFilledInput-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
    padding-top: 2px;
    padding-left: 0px;
  }

  input.MuiInputBase-input.MuiFilledInput-input.css-2bxn45 {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
  }
}

.search-pop {
  .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: #faa61a !important;
    font-size: 25px;
    margin-top: 0.2rem;
    margin-right: 0.2rem;
  }

  .MuiAutocomplete-endAdornment button svg {
    color: #faa61a !important;
    font-size: 24px !important;
    margin-top: -2px !important;
  }

  .MuiAutocomplete-endAdornment .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root) {
    color: #faa61a !important;
  }
}


.search-cselect {
  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.select-dropdwn {
    width: 100% !important;
  }
}



.radius-slider-div {
  .radius-slider {
    span.MuiSlider-track {
      color: #faa61a !important;
    }

    span.MuiSlider-thumb.MuiSlider-thumbColorPrimary.MuiSlider-thumbSizeMedium {
      color: #faa61a !important;
      box-shadow: 3px 4px 17px 0px rgba(0, 0, 0, 0.8);
      border: 2px solid #fff;
      width: 25px;
      height: 25px;
    }

    span.MuiSlider-rail {
      background: #fff !important;
      opacity: 1;
    }

    span.MuiSlider-valueLabelOpen.MuiSlider-valueLabel.css-nnid7-MuiSlider-valueLabel {
      background: #fff !important;
      color: #2d2d2d !important;
      font-family: 'GothamBold' !important;
    }


  }

  span.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.Mui-disabled.radius-slider.disabled {
    span.MuiSlider-track {
      color: #6d6d6d !important;
    }

    span.MuiSlider-thumb.MuiSlider-thumbColorPrimary.MuiSlider-thumbSizeMedium {
      color: #6d6d6d !important;
      box-shadow: unset;
      border: 2px solid #6d6d6d;
      width: 25px;
      height: 25px;
    }

    span.MuiSlider-rail {
      background: #6d6d6d !important;
      opacity: 1;
    }

    span.MuiSlider-valueLabelOpen.MuiSlider-valueLabel.css-nnid7-MuiSlider-valueLabel {
      background: #6d6d6d !important;
      color: #2d2d2d !important;
      font-family: 'GothamBold' !important;
    }
  }
}


.search-radio-btns {
  label.MuiFormControlLabel-root.Mui-disabled.MuiFormControlLabel-labelPlacementEnd.radio-grp p {
    color: gray !important;
  }
}


.search-subgrid-inner {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  gap: 1rem;
  width: 100%;
  box-sizing: border-box;
}

// .search-subgrid {
//   display: grid;
//   grid-template-columns: 1fr auto;
//   align-items: center;
//   gap: 1rem;
//   box-sizing: border-box;
//   width: 100%;
// }

.search-subgrid {
  display: flex;
  align-items: center;
  gap: 1rem;
  box-sizing: border-box;
  width: 100%;
  flex-wrap: wrap;
}

.search-btn-grid {
  display: grid;
  grid-template-columns: auto auto;
  gap: 2rem;
  align-items: center;
  box-sizing: border-box;
}

@media (max-width:768px) {
  .search-btn-grid {
    // display: grid;
    grid-template-columns: auto !important;
    width: 100%;
    box-sizing: border-box;
    gap:1rem !important; 
    // gap: 2rem;
    // align-items: center;
    // box-sizing: border-box;
  }
}

.search-subgrid-inner {
  width: 50% !important;
}

.reset-grid {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 1.5rem;
}

@media (max-width:1100px) {
  .search-div {
    width: 100% !important;
  }


}

@media (max-width: 1024px) {
  .search-div {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  .search-result-btn {
    font-size: 16px !important;
  }

  .search-subgrid-inner {
    width: 43% !important;
  }

}

@media (max-width: 768px) {
    .search-result {
        top: 6.4rem !important;
      }

  .search-subgrid-inner {
    width: 100% !important;
  }


  .search-subgrid {
    display: flex;
    grid-template-columns: 1fr !important;
    flex-wrap: wrap;
  }

  .search-form-container {
    height: 300px !important;
  }

  .search-div .search-grid {
    display: flex !important;
    flex-wrap: wrap;
    // grid-template-columns: 1fr auto auto auto;
    align-items: center;
    row-gap: 1rem;
    column-gap: 2rem;
  }

  .search-div {
    width: 100% !important;
  }
}

@media only screen and (min-width: 577px) and (max-width: 600px) {
      .search-result {
          top: 5.4rem !important;
        }
}

@media (max-width: 576px) {
    .search-result {
        top: 5.4rem !important;
      }
  .search-div {
    width: 100% !important;

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1.MuiPaper-root.MuiMenu-paper.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation8.MuiPopover-paper.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
      max-height: 230px;
      max-width: 140px !important;
      left: 35px !important;
    }

    li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.list-eqp-select-options.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
      font-size: 11px !important;
      padding: 5px !important;
    }
  }

  .search-form-container {
    height: 245px !important;
  }

  .search-main-container {
    padding: 1rem;
  }

  .search-btn-grid {
    gap: 1rem !important;

    .search-result-btn {
      font-size: 14px !important;
      height: 40px;
    }

    .radio-grp.list-eqp-page p {
      color: #fff !important;
      font-size: 14px !important;
      display: flex;
      align-items: center;
    }

    .radio-grp {
      align-items: center !important;
    }
  }

    .download-popup {
      width: 340px !important;
    }

        // .filter-popup {}


}

@media (max-width: 413px) {
  .search-btn-grid {
      gap: 1rem !important;
  
      .search-result-btn {
        font-size: 12px !important;
        height: 40px;
      }
  
      .radio-grp.list-eqp-page p {
        color: #fff !important;
        font-size: 12px !important;
        display: flex;
        align-items: center;
      }
  
      .radio-grp {
        align-items: center !important;
      }
    }

        .download-popup {
          width: 300px !important;
        }
}

@media (max-width:360px) {
  .search-btn-grid .search-result-btn {
      font-size: 12px !important;
      padding: 0.5rem !important;
    }

        .search-btn-grid .radio-grp.list-eqp-page p {
          color: #fff !important;
          font-size: 12px !important;
          display: flex;
          align-items: center;
        }
}

@media (max-width:320px) {
  .search-subgrid {
    grid-template-columns: 1fr !important;
  }
}

.search-box .MuiInputLabel-shrink {
  color: #2d2d2d !important;
}

.search-box .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root {
  border-radius: 7px;
  outline: 3px solid #faa61a;
  height: 50px;
}

span.search-cross-icon {
  width: 40px;
  font-size: 21px;
  position: absolute;
  color: #faa61a;
  top: 50%;
  font-weight: 700;
  cursor: pointer;
  right: 0%;
  transform: translate(-0%,-50%);
}

 #filled-number {
  padding: 18px 14px !important;
}

.equipment-filter-grid {
  display: grid !important;
  grid-template-columns: repeat(2,1fr);
  column-gap: 12px !important;
}

.equipment-filter-grid div:nth-child(2) {
  grid-row: 2;
}
.equipment-filter-grid div:nth-child(4) {
  align-self: center;
  justify-self: center;
}